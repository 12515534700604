export const aboutUsTitle = 'Хто такий Плетений КіТ та до чого тут реінкарнація?';

export const aboutUsValues = [
    'Плетений КіТ - сімейне хоббі, яке ми назвали так з любові до ручної роботи та, звісно, котів. Веду всю онлайнщину я, Маргарита. Моя мама (Єлизавета) все життя любила плести і в дитинстві половину мого гардеробу складали плетені речі. На якийсь час вона відклала своє хобі із-за роботи і повернулась до нього в останні кілька років.',
    'Обрала вона - плести інтер\'єрні подушки, побачивши в пінтересті купу гарних фото, а я пообіцяла допомогти з їх зшиванням, бо займалась багато років виготовленням м\'яких іграшок і трохи то всьо вмію. Для роботи вона мала вдосталь залишків ниток від попередніх робіт, пороспускала светри, які колись не доплела, чи мала переробити і так почали з\'являтись перші КотоПодушки. Мама відправляла мені деталі (живемо в різних містах), я шила, набивала та вишивала котоЛиця, фотографувала і викладала їх в інтернеті.',
    'Обкластись плетеним не входило в наші плани, тож я спочатку просто заливала подушки-іграшки на різні сайти для продажу хендмейду, а потім нам захотілось взяти участь в якомусь з ярмарків, і потрібно було мати сторінку бренду та назву. Ми дуже любимо тварин і маємо котів, першими подушками були КотоПодушки, тож назва прийшла сама собою.'
];

export const contactTitle = 'Замовлення';

export const formTitle = 'Нам можна написати';

export const careConditionsTitle = 'Умови догляду';

export const careConditions = [
    'До кожного виробу, який може викликати запитання про прання, я додаю в пакування роздруковані умови догляду. До рукавиць і мітенок не додаю, бо їх важко розтягти, зіпсути, вони не линяють і я свої перу зі всім пранням.',
    'Наявні тут подушки діляться на подушки-іграшки та інтер\'єрні плетені подушки.',
    'Подушки-іграшки неможливо зробити зі зйомною плетеною частиною, оскільки там всюди нашиті кінцівки або подушку прошито наскрізь. Тому я раджу прати їх вручну та сушити на горизонтальній поверхні, щоб наповнювач не збився в один бік та не розтягнув плетену частину. Я знаю, що деякі мої замовники_ці перуть подушки в пралці, і кажуть, що все добре. Якщо ваша пралка має режим делікатного прання та дійсно делікатно пере, то, думаю, можна попрати і в ній. Проте це на ваш розсуд!',
    'Також в наявності є квадратні інтер\'єрні подушки. Я вшиваю в плетену частину застібку-блискавку, щоб можна було той плетений напірник зняти і попрати окремо від подушки. Подушки шию з бавовни та набиваю, як і решту, холофайбером.',
]

export const filterTitle = 'Фільтри';

export const filterCheap = 'від дешевших';

export const filterExpensive = 'від дорожчих';